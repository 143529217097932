import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/monkey/Projects/bkc-sajt/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import HomepageTile from '../components/HomepageTile';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const PageDescription = makeShortcode("PageDescription");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const Carousel = makeShortcode("Carousel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Bosanski kulturni centar Gračanica`}</h2>
    <Row mdxType="Row">
      <PageDescription mdxType="PageDescription">
        <p>{`Osnovan 1992. godine, BKC Gračanica se razvio iz Narodnog univerziteta i Doma kulture,`}</p>
      </PageDescription>
      <Column colLg={8} colMd={8} noGutterSm mdxType="Column">
        <p>{`nastavio njegovu ulogu javne kulturne ustanove širokog spektra djelovanja: promocije knjiga, pozorišne predstave, tribine, izložbe, modne revije, književni susreti, poetske večeri, te različiti gostujući kulturni programi i sadržaji.`}</p>
      </Column>
    </Row>
    <Row className='homepage-row' mdxType="Row">
  <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ImageCard title="Zavičajna muzejska zbirka" href="/zmz" actionIcon="launch" titleColor="dark" iconColor="dark" hoverColor="dark" className="card-background" mdxType="ImageCard">
          <img {...{
            "src": "../images/zbirka.png",
            "alt": "Zavičajna muzejska zbirka"
          }}></img>
 </ImageCard>
  <ImageCard title="Radio Gračanica" href="/rg" actionIcon="arrowRight" titleColor="light" iconColor="light" className="card-background" mdxType="ImageCard">
          <img {...{
            "src": "../images/radio.png",
            "alt": "Radio Gračanica"
          }}></img>
 </ImageCard>
      </Column>
      <Column colLg={4} colMd={4} noGutterSm mdxType="Column">
  <ImageCard title="Kino Gračanica" href="/kino" actionIcon="arrowRight" titleColor="light" iconColor="light" className="card-background" mdxType="ImageCard">
          <img {...{
            "src": "../images/kino.png",
            "alt": "Kino Gračanica"
          }}></img>
 </ImageCard>
  <ImageCard title="Gradska Biblioteka" href="/biblioteka" actionIcon="launch" titleColor="dark" iconColor="daek" className="card-background" mdxType="ImageCard">
          <img {...{
            "src": "../images/biblioteka.png",
            "alt": "Kino Gračanica"
          }}></img>
        </ImageCard>
      </Column>
      <Column colLg={12} colMd={12} noGutterSm mdxType="Column">
  <HomepageTile ratio={{
          default: '1:2',
          md: '16:9'
        }} actionIcon="no-icon" mdxType="HomepageTile">
    <Carousel id="c1" count="1 2" nav={false} fade={true} autoPlay mdxType="Carousel">
      <img src={'../images/bkc-galerija2.png'} sizes="(max-width: 600px) 100vw, 1920px" alt="BKC" />
      <img src={'../images/bkc-galerija.png'} sizes="(max-width: 600px) 100vw, 1920px" alt="Galerija slika" />
    </Carousel>
    <HomepageTile ratio={{
            default: '2:1',
            md: '4:1',
            lg: '6:1'
          }} theme="dark" title="Galerija" actionIcon="download" link="/galerija" target="_blank" mdxType="HomepageTile" />
  </HomepageTile>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      